import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import LocandineMenuViewer from './LocandineMenuViewer';

const LocandineViewer = () => {
    var imgs=["3","4","5","6","7","8","9","10","11","12","13"];
    return (
        <PhotoProvider>
            <div style={{display:"flex",padding:"30px",flexWrap:"wrap",gap:"20px",justifyItems:"center",justifyContent:"center"}}>
                <LocandineMenuViewer/>
                {imgs.map((link,i)=>{
                    return (
                        <PhotoView key={i} src={require(`../img/locandine/${link}.jpeg`)}>
                            <img data-aos="flip-left" data-aos-duration="300" style={{width:"min(300px,90%)",borderRadius:"10px",boxShadow:"3px 3px 5px rgba(0,0,0,0.3)"}} alt="" src={require(`../img/locandine/${link}.jpeg`)} />
                        </PhotoView>
                    )
                })}
            </div>
        </PhotoProvider>
    )
}

export default LocandineViewer