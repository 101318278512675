import { useEffect,useState } from 'react'
import "./Scontrino.css"
import close_icon from "../img/icons/close.png"
import warn_icon from "../img/icons/warning.png"
import ws from "../img/icons/ws.png"
import logo from "../img/logo_nero.png"

var sconto_da_applicare=0 //in percentuale

const Scontrino = (props) => {
    const [messaggio, setmessaggio] = useState(<></>);
    const [valueButton, setvalueButton] = useState("COPIA");
    const [scontonum, setscontonum] = useState(0);
    const [totale_finale, settotale_finale] = useState(props.totale);
    useEffect(() => {
        var contaProdotti=0
        var tot=props.totale
        props.carrello.forEach((el)=>{
            if(el.tipo!=="bevanda"){
                contaProdotti+=el.quant
            }
        })
        if(contaProdotti>=10){
            settotale_finale(sconto(props.totale))
            tot=sconto(props.totale)
            setscontonum(props.totale*(1/sconto_da_applicare))
        }
        setmessaggio(
            <div style={{display:"flex",flexDirection:"column",gap:"5px"}}>
                <span>Nome: {props.ordine.nome}</span>
                <span>Giorno: {formatdata(props.ordine.data)}</span>
                <span>Ora: {props.ordine.orario}</span>
                <span>Menù: {props.ordine.menu}</span>
                <span>Ordine:</span>
                {
                    props.carrello.map((el)=>{
                        return <span>{el.nome} {el.quant!==1?`x${el.quant}`:null}</span>
                    })
                }
                <span>Totale: {tot}€</span>
            </div>
        )
        
    }, []);
    const formatdata=(data)=>{
        data=data.split("-")
        let dt=data[2]+"/"+data[1]+"/"+data[0]
        return dt
    }
    const close=()=>{
        props.setscont_open(false)
    }
    function sconto(prezzo){
        let percent=1/sconto_da_applicare
        prezzo=prezzo-(prezzo*percent)
        return prezzo.toFixed(2)
    }
    const copia=(event)=>{
        var range = document.createRange();
        range.selectNode(document.querySelector(".scontrinoElements"));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        setvalueButton("COPIATO")
        event.target.disabled=true
    }
    return (
        <div className='infoscreen scontrino'>
            <img alt="ico" className='icon chiudi black' src={close_icon} onClick={close}/>
            <img alt="logo" className='logo' style={{width:"30%"}} src={logo}/>
            <h2>Copia il testo e invialo!</h2>
            <div id="warn-label">
                <span>
                    <img src={warn_icon} alt="warning"/>
                    <h3>Attenzione</h3>
                </span>
                <div>
                    E' consigliabile effettuare l'ordine <strong>almeno 3 ore prima</strong> e attendere la <strong>conferma</strong> d'ordine. Se non viene ricevuta conferma entro <strong>30 minuti</strong>, contattare telefonicamente il locale.
                </div>
            </div>
            <div className='scontrinoElements'>
                <input type="button" id="copiaButton" value={valueButton} onClick={copia}/>
                {messaggio}
            </div>
            <span style={{marginBottom:"20px"}}>
                {scontonum!==0?<>Hai risparmiato <span style={{color:"green"}}>{scontonum}€</span>!</>:null}
            </span>
            <div id="contact">
                <a href="https://wa.me/393469671578">
                    <div>
                        <img alt="ico" src={ws} className="icon"/>
                        Apri la chat   
                    </div>
                </a> 
            </div>
            <div className="totale">
                <span>Totale:</span>
                <span>{totale_finale}€</span>
            </div>
        </div>
    )
}

export default Scontrino
